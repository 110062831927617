.CreateHair {
  padding-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.CreateHairInput {
  display: flex;
  gap: 1rem;
  gap: 3rem;
  flex-direction: column;
}
.CreateHairInputAll {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
}
