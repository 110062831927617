.CreateLength {
  /* display: flex; */
}
.CreateLengthCon {
  display: flex;

  flex-direction: column;
  gap: 1rem;
}
.CreateLengthButtonCon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;
}
