.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1200px;
}

.card {
  display: flex;
  gap: 2rem;
  margin: 1rem;
  flex-direction: column;
  padding: 1rem;
  min-width: 306px;
  min-height: 30rem;
  border: 1px solid #ccc;
  align-items: center;
  border-radius: 5px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
  transition: all 0.3s ease-in-out;
}

.imageContainer {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hairImage {
  width: 10rem;
  height: 10rem;
}

.productLine {
  border: 1px solid #420101;
  min-width: 10rem;
}

.productsName {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #420101;
}
.productsName > h3 {
  text-decoration: underline;
  text-decoration-color: rgb(8, 8, 8);
}

.productsName > h1 {
  font-family: cursive;
  text-decoration: underline;
  text-decoration-color: rgb(8, 8, 8);
}

.button5 {
  appearance: button;
  background-color: #1652f0;
  border: 1px solid #1652f0;
  border-radius: 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Graphik, -apple-system, system-ui, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 1.15;
  overflow: visible;
  padding: 12px 16px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: all 80ms ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
}

.button5:hover {
  background-color: #0a46e4;
  border-color: #0a46e4;
}

.button5:active {
  background-color: #0039d7;
  border-color: #0039d7;
}

.createLengthCon {
  display: flex;
}

.createLength {
  display: flex;
  margin: 0.6rem;
  flex-direction: column;
  padding: 0.9rem;
  border: 1px solid #ccc;
  gap: 0.6rem;
  transition: all 0.3s ease-in-out;
}

.CreateGallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  height: 90vh;
}
